import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const OnlineNews = () => {
    return (
        <Layout>
            <Seo title="Online News" />

            <h1 className="title">Online News</h1>

            <SearchInput />

            <table>
                <tbody>
                    <tr className="thead title">
                        <td colSpan="2">Australian news</td>
                    </tr>

                    <tr>
                        <td>Australian Financial Review</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.afr.com/">https://www.afr.com/</a></td>
                    </tr>

                    <tr>
                        <td>The Australian</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.theaustralian.com.au/">https://www.theaustralian.com.au/</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">US news</td>
                    </tr>

                    <tr>
                        <td>USA Today</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.usatoday.com/">https://www.usatoday.com/</a></td>
                    </tr>

                    <tr>
                        <td>New York Times</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.nytimes.com/">https://www.nytimes.com/</a></td>
                    </tr>

                    <tr>
                        <td>Washington Post</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.washingtonpost.com/">https://www.washingtonpost.com/</a></td>
                    </tr>

                    <tr>
                        <td>Los Angeles Times</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.latimes.com/">https://www.latimes.com/</a></td>
                    </tr>

                    <tr>
                        <td>CNN</td>
                        <td><a target="_blank" rel="noreferrer" href="https://edition.cnn.com/">https://edition.cnn.com/</a></td>
                    </tr>

                    <tr>
                        <td>ESPN</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.espn.com.au/">https://www.espn.com.au/</a></td>
                    </tr>

                    <tr>
                        <td>NPR</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.npr.org/">https://www.npr.org/</a></td>
                    </tr>

                    <tr>
                        <td>Google news</td>
                        <td><a target="_blank" rel="noreferrer" href="https://news.google.com/">https://news.google.com/</a></td>
                    </tr>

                    <tr>
                        <td>CNET technology news</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.cnet.com/news/">https://www.cnet.com/news/</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">UK News</td>
                    </tr>

                    <tr>
                        <td>BBC</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.bbc.com/news">https://www.bbc.com/news</a></td>
                    </tr>
                </tbody>
            </table>
        </Layout>
    )
}

export default OnlineNews